<template>
	<div class="choose-format">
		<div class="container">
			<Tab :items="tabItems"></Tab>
		</div>
	</div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Tab from "../../../../../../shared/components/Tab"
    import {FORMAT_TYPES} from "../../../../../../shared/constants";


    export default {
        name: "SetapNavigation",
        components: {Tab},
		methods: {
            activeClass: function (name) {
                return this.$route.name.includes(name);
            }
		},
        computed: {
            tabItems() {
                return [
                    {
                        name: 'choose-format',
                        route: {name: 'dashboard.setup-form.choose-format'},
                        title: 'Choose Format',
                        icon: '1',
                    },
                    {
                        name: 'build-form',
                        route: {name: 'dashboard.setup-form.build-form'},
                        title: 'Build Form',
                        icon: '2',
                        isHidden : this.$route.name.includes('choose-format')
                    },
                    {
                        name: 'embed-code',
                        route: {name: 'dashboard.setup-form.embed-code'},
                        title: this.thirdTabTitle,
                        icon: '3',
                        isHidden: this.$route.name.includes('choose-format')
                    }
                ]
            },

            thirdTabTitle() {
                let title = 'Embed Code';
                if (this.chosenFormat && Object.prototype.hasOwnProperty.call(this.chosenFormat,  'type')) {
                    switch (this.chosenFormat.type) {
                        case FORMAT_TYPES.EMBED:
                            title = 'Embed Code';
                            break;
                        case FORMAT_TYPES.POPUP:
                            title = 'Popup Code';
                            break;

                        case FORMAT_TYPES.LANDING_PAGE:
                            title = 'Copy Url';
                            break
                    }
				}

                return title;
            },
            ...mapGetters({
                user: "auth/getUser",
                chosenFormat: "setupForm/getFormat"
            })
        }
    }
</script>
