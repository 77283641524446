<template>
	<div>
		<PageHeader title="Setup Audit Form"
					:show-settings-button="true"
					:onSettingsClick="openDomainSettings"
					button-name="Domain Settings"/>
		<SetupNavigation></SetupNavigation>
		<section class="setup-form">
			<div class="container">
				<router-view @openDomainSettings="openDomainSettings" ></router-view>
			</div>
		</section>

		<Modal modalId='domainSettings'
			   title="Domain Settings"
			   size="s"
			   :handle-ok="openManageDomainPopup"
			   submit-button-text="Manage Custom Domain">
			<div class="modal-form-group">
				<div class="form-group">
					<label>Default Domain Provided by Site Auditor</label>
					<div class="form-group-items">
						<input class="form-control" type="text" readonly v-model="defaultDomain">
						<button class="btn btn-primary modal-small-btn" title="Copy"
								v-clipboard="() => defaultDomain"
								@success="handleSuccess"
								@error="handleError">
							<i class="icon-copy"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="modal-form-group">
				<div class="form-group">
					<label>Custom Domain <a class="blue-link" v-if="!customDomain" @click="openManageDomainPopup">
						Set up custom domain</a></label>

					<div class="form-group-items">
						<input class="form-control" readonly :value="customDomainWithProtocol"
							   :class="{valid: isValid && customDomain, not_valid : !isValid && customDomain}"
							   placeholder="Custom Domain not connected">

						<button class="btn modal-small-btn" type="button" title="Validate"
								:class="{'btn-secondary disabled' : !customDomain, 'btn-primary' : customDomain}"
								@click="validateDomain">
							<i class="svg-image-valid"></i>
						</button>
						<button class="btn modal-small-btn" type="button"  title="Delete"
								:class="{'btn-secondary disabled' : !customDomain, 'btn-primary' : customDomain}"
								@click="deleteCustomDomain">
							<i class="icon-delete"></i>
						</button>

						<button class="btn modal-small-btn" title="Copy"
								:class="{'btn-secondary disabled' : !customDomain, 'btn-primary' : customDomain}"
								v-clipboard="() => customDomain"
								@success="handleSuccess"
								@error="handleError">
							<i class="icon-copy"></i>
						</button>
					</div>
					<p v-if="customDomain && !isValid" class="form-txt form-txt--red">
						<i class="svg-image-valid-red"></i>
						<span>Link is inactive. Check again later.</span>
					</p>
					<p v-if="customDomain && isValid" class="form-txt form-txt--green">
						<i class="svg-image-valid-green"></i>
						<span>Link is active</span>
					</p>
				</div>
			</div>
		</Modal>


		<!--		Manage Custom Domain Popup-->

		<Modal modalId='manageCustomDomainSettings'
			   title="Manage Custom Domain"
			   size="s"
			   :handle-ok="backToDomainSettings"
			   submit-button-text="Back to Domain Settings">

			<div class="modal-steps">

				<div class="modal-step">
					<form @submit.prevent="saveCustomDomain">
						<p class="modal-step__num">1</p>
						<p class="modal-step__txt bolder c-black">Enter the URL on which you want the Audit Tool page
							and Report page to be displayed?</p>
						<p class="modal-step__subtxt">Example: audit.yoursite.com</p>
						<div class="form-group-items">

							<div class="form-group">
								<input type="text" class="form-control" required placeholder="Subdomain"
									   v-model="subDomain" @input="error=''">
							</div>
							<div class="form-group">
								<input type="text" class="form-control" required placeholder="Domain" v-model="domain">
							</div>
						</div>
						<div class="invalid-feedback">{{error}}</div>
						<button class="btn btn-primary" type="submit">Save</button>
					</form>
				</div>
				<div class="modal-step">
					<p class="modal-step__num">2</p>
					<p class="modal-step__txt bolder c-black">1. Login to your Domain hosting provider (like godaddy,
						bluehost etc.)</p>
					<p class="modal-step__txt bolder c-black">2. Go to DNS settings for your domain</p>
					<p class="modal-step__txt bolder c-black">3. Create a CNAME record with the following details
					</p>
					<div class="modal-step__form-group">
						<div class="form-group">
							<label for="subDomain">Host:</label>
							<input type="text" readonly class="form-control" id="subDomain" v-model="subDomain"
								   placeholder="Your subdomain">
							<button class="btn btn-primary modal-small-btn"
									v-clipboard="() => subDomain"
									@success="handleSuccess"
									@error="handleError">
								<i class="icon-copy"></i>
							</button>
						</div>
						<div class="form-group">
							<label for="cname">Value:</label>
							<input type="text" readonly class="form-control" id="cname" :value="cname">
							<button class="btn btn-primary modal-small-btn"
									v-clipboard="() => cname"
									@success="handleSuccess"
									@error="handleError">
								<i class="icon-copy"></i>
							</button>
						</div>
					</div>
					<p></p>
					<p class="modal-step__txt bolder c-black">4. Save</p>
					<div class="alert alert-secondary" role="alert">It takes 24 to 48 hours for your hosting provider to
						reflect this change.
					</div>
				</div>
			</div>
		</Modal>
	</div>

</template>

<script>

    import PageHeader from "../../../../../shared/components/PageHeader";
    import Modal from "../../../../../shared/components/Modal";

    import SetupNavigation from "./components/SetupNavigation";
    import {mapGetters} from "vuex";
    import {validate} from "vee-validate";

    export default {
        name: "setup-audit-form",
        components: {Modal, SetupNavigation, PageHeader},
        data() {
            return {
                defaultDomain: '',
                customDomain: '',
                isValid: false,
                cname: '',
                subDomain: '',
                domain: '',
                error: ''
            }
        },
        methods: {
            openDomainSettings() {
                this.defaultDomain = `${this.user.domain}.${process.env.VUE_APP_DOMAIN}`;
                this.cname = process.env.VUE_APP_CNAME;

                this.$store.dispatch("domainSettings/getSettings").then((response) => {

                    if (response.settings) {
                        this.isValid = response.settings.is_cname_valid;
                        this.customDomain = response.settings.white_label_url;
                        this.subDomain = this.customDomain.slice(0, this.customDomain.split(".")[0].length);
                        this.domain = this.customDomain.slice(this.customDomain.split(".")[0].length + 1, this.customDomain.length);
                    }
                    this.$bvModal.show('domainSettings');
                }).catch((error) => {
                    this.showErrorNotifications(error);
                });

            },
            openManageDomainPopup() {
                this.closeDomainSettingsPopup();
                this.error = '';
                this.$bvModal.show('manageCustomDomainSettings');

            },
            validateDomain() {

                if (this.customDomain) {
                    this.$store.dispatch("domainSettings/validateDomain").then((response) => {
                        this.isValid = response.is_valid;

                        if (this.isValid) {
                            this.showNotifications({message: 'Validated', type: 'success'});
						} else {
                            this.showNotifications({message: 'Custom Domain is not Valid', type: 'danger'});
						}

                    }).catch((error) => {
                        this.showErrorNotifications(error);
                    });
                }

            },
            saveCustomDomain($event) {
                $event.preventDefault();
                validate(this.subDomain, "required|alpha").then(result => {
                    if (result.valid) {
                        let white_label_url = this.subDomain + '.' + this.domain;
                        if (this.customDomain !== white_label_url) {
                            let sendData = {
                                white_label_url: white_label_url,
                                is_cname_valid: false
                            };
                            this.$store.dispatch("domainSettings/saveSettings", sendData).then(() => {
                                this.showNotifications({message: 'Custom Domain has been updated', type: 'success'});

                            }).catch((error) => {
                                this.showErrorNotifications(error);
                            });
                        }

                    } else {
                       this.error = "Subdomain must contain alphabets only";
                    }
                });


            },
            deleteCustomDomain() {
                if (this.domain) {
                    if (confirm("Do you really want to delete Custom Domain")) {
                        this.$store.dispatch("domainSettings/deleteSettings").then(() => {
                            this.customDomain = this.subDomain = this.domain = '';

                            this.showNotifications({message: 'Custom Domain has been deleted', type: 'success'});

                        }).catch((error) => {
                            this.showErrorNotifications(error);
                        });
                    }
                }

            },

            backToDomainSettings() {
                this.closeManageDomainPopup();
                this.openDomainSettings()
            },
            closeDomainSettingsPopup() {
                this.$bvModal.hide('domainSettings');
            },
            closeManageDomainPopup() {
                this.error = '';
                this.$bvModal.hide('manageCustomDomainSettings');
            },
            handleSuccess() {
                this.showNotifications({message: 'Copied to clipboard', type: 'success'});
            },

            handleError() {
                this.showNotifications({message: 'Can not copy', type: 'danger'});
            }
        },
        computed: {
            customDomainWithProtocol() {
                return this.customDomain ? 'https://' + this.customDomain : '';
			},
            ...mapGetters({
                user: "auth/getUser"
            })
        }


    }
</script>

<style lang="scss">
	@import "../../../../../shared/assets/styles/blocks/choose-format";
	@import "../../../../../shared/assets/styles/blocks/setup-audit-form";

</style>
<style scoped>
	.form-control:disabled, .form-control[readonly] {
		background-color: #FFFFFF;
	}
	.invalid-feedback {
		display: block;
		margin-bottom: 10px;
	}

</style>
